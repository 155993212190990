/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { colors, device, styles } from '../styles/globals';
import DefaultLayout from '../containers/default';
import {
  ASUIcon,
  BassettIcon,
  ClinicalScienceIcon,
  // DOHCIcon,
  FDAIcon,
  FroedtertIcon,
  HarvardIcon,
  MayoIcon,
  OHRPIcon,
  StanfordIcon,
  UCSFIcon,
} from '../components/icons';
import {
  SectionTitle,
  SectionDescription,
} from '../components/section';
import {
  MainBannerItem,
  Banner,
  BannerItem,
  BannerTitle,
  BannerDescription,
} from '../components/banner';
import { PUBLICATIONS } from '../constants/clinical-science';

import '../styles/index.css';

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor || colors.white};
  padding: 144px 15%;

  @media ${device.desktopS} {
    padding: 144px 10%;
  }

  @media ${device.laptopL} {
    padding: 50px 6%;
  }

  @media ${device.tablet} {
    width: 100%;
    padding: 72px 55px;  
  }

  @media ${device.mobileL} {
    padding: 72px 35px;
  }
`;

const TeamContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  gap: 48px;

  @media ${device.laptopM} {
    gap: 42px;
    flex-direction: column;
  }

  @media ${device.tablet} {
    gap: 36px;
    margin-top: 24px;
  }

  @media ${device.mobileL} {
    margin-top: 22px;
  }
`;

const TeamItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TeamItemTitle = styled.p`
  font-size: ${(props) => props.secondary ? '18px' : '24px'};
  line-height: ${(props) => props.secondary ? '24px' : '38px'};
  font-weight: 700;
  margin-bottom: ${(props) => props.secondary ? '18px' : '2px'};
  color: ${styles.baseFontColor};

  @media ${device.tablet} {
    font-size: ${(props) => props.secondary ? '16px' : '20px'};
    line-height: ${(props) => props.secondary ? '20px' : '36px'};
  }

  @media ${device.mobileL} {
    font-size: ${(props) => props.secondary ? '14px' : '18px'};
  }
`;

const ConfirmationItem = styled.div`
  display: flex;
  gap: 48px;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 96px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-bottom: 0;
      padding-bottom: 48px;
      border-bottom: 2px solid ${colors.grey1};
    }

    &:last-child {
      padding-top: 48px;
    }
  }
`;

const ConfirmationIcon = styled.div`
  background-color: ${colors.grey1};
  padding: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 394px;

  @media ${device.tablet} {
    max-width: 400px;
  }

  @media ${device.tabletS} {
    max-width: 100%;
  }
`;

const AnchorLink = styled.a`
  font-size: 18px;
  font-family: Gotham SSm;
  color: ${styles.baseFontColor};
  text-decoration: underline;

  @media ${device.tablet} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const PartnersContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 48px;

  @media ${device.laptopM} {
    grid-gap: 30px;
  }

  @media ${device.tablet} {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tabletS} {
    grid-gap: 0;
    grid-template-columns: 1fr;
    margin-top: 24px;
  }

  @media ${device.mobileL} {
    margin-top: 22px;
  }
`;

const PartnersItem = styled.div`
  padding: 24px 0;

  @media ${device.tabletS} {
    padding: 36px 0;
    border-top: 1px solid #DEDEDE;

    &:last-child {
      border-bottom: 1px solid #DEDEDE;
    }
  }
`;

const PublicationsContainer = styled.div`
  width: 100%;
  margin-top: 30px;

  @media ${device.tabletS} {
    margin-top: 24px;
  }

  @media ${device.mobileL} {
    margin-top: 22px;
  }
`;

const PublicationsItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 9px 0;
  border-top: 1px solid #DEDEDE;

  &:last-child {
    border-bottom: 1px solid #DEDEDE;
  }

  @media ${device.mobileL} {
    p {
      font-size: 12px;
    }
  }
`;

const PublicationName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    width: calc(100% - 60px);
  }

  @media ${device.mobileL} {
    width: calc(100% - 40px);
  }
`;

const PublicationLink = styled(AnchorLink)`
  white-space: nowrap;
  display: inline;
`;

const PublicationSource = styled(SectionDescription)`
  white-space: nowrap;
  font-style: italic;
  display: inline;
`;

const PublicationNote = styled(SectionDescription)`
  width: 60px;
  margin-left: 10px;
  white-space: nowrap;

  @media ${device.tablet} {
    text-align: end;
  }

  @media ${device.mobileL} {
    width: 40px;
  }
`;

const SectionLi = styled.li`
  padding-bottom: 16px;
`;

export default () => (
  <DefaultLayout>
    <Helmet>
      <title>all.health Clinical Science</title>
      <meta
        name="description"
        content="all.health Clinical Science"
      />
    </Helmet>
    <Banner reverse backgroundColor={colors.blue}>
      <BannerItem left wide>
        <BannerTitle color={colors.white}>
          we are deeply
          <br />
          rooted in science
        </BannerTitle>
        <BannerDescription color={colors.white}>
          The all.health system includes over 1,000 IP assets and provides insights into
          emerging risks to patient health and wellness that are grounded in clinical
          science and research. Our world-class team of physicians and scientists work through
          a rigorous scientific and clinical process so the system delivers proven clinical-grade
          results and actionable insights. External experts validate the all.health system and
          its advances, evidenced by FDA registration and listing of core components in the all.health system.
        </BannerDescription>
      </BannerItem>
      <MainBannerItem right isIcon wide>
        <ClinicalScienceIcon />
      </MainBannerItem>
    </Banner>
    <Section backgroundColor={colors.grey1}>
      <SectionTitle>
        clinical & science team
      </SectionTitle>
      <SectionDescription>
        Our key leaders build on their world-class experience in training, practice, and research.
      </SectionDescription>
      <TeamContainer>
        <TeamItem>
          <TeamItemTitle>
            David Benaron, MD
          </TeamItemTitle>
          <TeamItemTitle secondary>
            Chief Medical Officer
          </TeamItemTitle>
          <SectionDescription>
            David Benaron, MD, Chief Medical Officer, is an ICU physician who
            developed multiple optical methods used today, including the green-light
            heart-rate sensor on 1 billion wrists. He received the 2023 SPIE Britton
            Chance Biomedical Optics Award for lifetime achievement in physics.
            Dr. Benaron studied biochemistry at the University of California,
            completed graduate school programs at Harvard Medical School and
            Massachusetts Institute of Technology's Health Sciences and Technology.
          </SectionDescription>
        </TeamItem>
        <TeamItem>
          <TeamItemTitle>
            Jessie Li, PhD
          </TeamItemTitle>
          <TeamItemTitle secondary>
            Chief Data Scientist
          </TeamItemTitle>
          <SectionDescription>
            Jessie Li, PhD, Chief Data Scientist, received her PhD in Computational
            Genomics from the University of Oxford. Dr. Li worked at a university
            spin-off as a statistical geneticist and at two healthcare technology
            companies where she implemented disease risk prediction models and worked
            in pharmaceutical bioinformatics-based discovery. She has over 20
            peer-reviewed publications in scientific journals.
          </SectionDescription>
        </TeamItem>
        <TeamItem>
          <TeamItemTitle>
            Omar Usman, MD
          </TeamItemTitle>
          <TeamItemTitle secondary>
            Head of Informatics and Chief&nbsp;Datologist
          </TeamItemTitle>
          <SectionDescription>
            Omar Usman, MD, Head of Informatics and Chief Datologist,
            is an emergency medicine physician in the U.S. Department of Veteran
            Affairs at Ann Arbor, MI. His diverse background includes a Fellowship
            in Medical Informatics at Stanford, Residency in Emergency Medicine at
            the University of Chicago, and ER physician position at Kaiser Permanente.
            He earned his MD at The Ohio State University, MBA at Northwestern Kellogg
            School where he was also a researcher at the Feinberg School of Medicine.
          </SectionDescription>
        </TeamItem>
      </TeamContainer>
    </Section>

    <Section>
      <ConfirmationItem>
        <ConfirmationIcon>
          <OHRPIcon />
        </ConfirmationIcon>
        <div>
          <SectionTitle>
            dedicated human studies IRB panel
          </SectionTitle>
          <SectionDescription>
            all.health has a US federally-recognized Institutional Review Board (IRB) panel that reviews
            all clinical studies performed at all.health. The IRB consists of clinical,
            data science, and engineering expertise, as well as outside input from non-scientists
            in the community. The panel ensures the integrity of research and enforces high
            academic standards. Results of trials performed under IRB review are more readily
            accepted for peer review and for FDA-approval submissions.
          </SectionDescription>
          <SectionDescription>
            all.health's IRB is a Federally-recognized Human Studies panel operating under
            the US Office for Human Research Protection.
          </SectionDescription>
        </div>
      </ConfirmationItem>
      <ConfirmationItem>
        <ConfirmationIcon>
          <FDAIcon />
        </ConfirmationIcon>
        <div>
          <SectionTitle>
            multispectral Algo SaMD™ oximeter validated to produce medical
            grade HRp, RRp, and SpO
            <sub>2</sub>
            {' '}
            from wrist wearable data
            {' '}
            <SectionDescription>[3]</SectionDescription>
          </SectionTitle>
          <SectionDescription>
            Our adherence to science led us to develop the first device that is FDA listed to
            create medical-grade pulse rate, breathing rate, and blood oxygen from wrist
            wearable data. [3] [4].
          </SectionDescription>
          <SectionDescription>
            Clinical trials have confirmed the accuracy of the device. FDA listing
            can be found on the FDA website under registration
            {' '}
            <AnchorLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfrl/rl.cfm?lid=766115&lpcd=PGJ"
            >
              3021140321
            </AnchorLink>
            .
          </SectionDescription>
        </div>
      </ConfirmationItem>
    </Section>

    <Section backgroundColor={colors.grey1}>
      <SectionTitle>
        clinical deployment partners of excellence
      </SectionTitle>
      <PartnersContainer>
        <PartnersItem>
          <BassettIcon />
        </PartnersItem>
        <PartnersItem>
          <FroedtertIcon />
        </PartnersItem>
        {/* <PartnersItem>
          <DOHCIcon />
        </PartnersItem> */}
      </PartnersContainer>
    </Section>

    <Section>
      <SectionTitle>
        clinical research at world-class institutions
      </SectionTitle>
      <PartnersContainer>
        <PartnersItem>
          <ASUIcon />
        </PartnersItem>
        <PartnersItem>
          <HarvardIcon />
        </PartnersItem>
        <PartnersItem>
          <MayoIcon />
        </PartnersItem>
        <PartnersItem>
          <StanfordIcon />
        </PartnersItem>
        <PartnersItem>
          <UCSFIcon />
        </PartnersItem>
      </PartnersContainer>
    </Section>

    <Section backgroundColor={colors.grey1}>
      <SectionTitle>
        peer-reviewed publications
      </SectionTitle>
      <SectionDescription>
        all.health, its team or its IP creators have been involved in various research opportunities,
        illustrated with abstracts and publications listed below.
      </SectionDescription>
      <PublicationsContainer>
        {PUBLICATIONS.map(publication => (
          <PublicationsItem key={publication.link}>
            <PublicationName>
              <PublicationLink
                target="_blank"
                rel="noopener noreferrer"
                href={publication.link}
              >
                {publication.label}
              </PublicationLink>
              <PublicationSource>
                {', '}
                {publication.source}
                .
              </PublicationSource>
            </PublicationName>
            <PublicationNote>{publication.notes}</PublicationNote>
          </PublicationsItem>
        ))}
      </PublicationsContainer>
    </Section>

    <Section>
      <SectionTitle>
        important notes
      </SectionTitle>
      <SectionDescription as="div" isList>
        <ol>
          <SectionLi>
            The all.health system does not diagnose, treat, manage, prevent, or
            cure disease. Clinical correlation is suggested.
          </SectionLi>
          <SectionLi>
            The US FDA has NOT evaluated any signals that we have labeled here as
            investigational or research signals, or marked by directing the reader to
            this note, and therefore they cannot be relied upon for diagnosis.
            Such research uses are unproven, must be labeled in the US and elsewhere
            “FOR INVESTIGATIONAL USE ONLY,” and may be used by physicians and patients
            only under IRB-approved Human Studies protocols with informed consent.
          </SectionLi>
          <SectionLi>
            Algo SaMD is intended for use in adult patients outside of hospitals,
            clinics, or transport in well-perfused adult subjects. It is intended for
            spot-checking and/or trend analysis. The following clinical parameters are
            output by the device:
          </SectionLi>
          <div style={{ paddingLeft: '24px' }}>
            <ol type="a">
              <SectionLi>
                The Pulse Rate (HRp) parameter is intended for the noninvasive measurement
                of pulse rate during no-motion and motion conditions.
              </SectionLi>
              <SectionLi>
                The Respiration Rate (RRp) parameter is intended for the noninvasive measurement
                of respiration rate during no-motion conditions.
              </SectionLi>
              <SectionLi>
                The Pulse Oximetry (Spo2) parameter is intended for the measurement of functional
                oxygen saturation of arterial hemoglobin during no-motion conditions.
              </SectionLi>
            </ol>
          </div>
          <SectionLi>
            The all.health System is not a critical care system, nor an urgent care system.
            Rather the system is designed to detect changes over time, resulting in delayed
            notifications. For urgent or emergent care, their Healthcare Provider and/or
            911 should be called.
          </SectionLi>
        </ol>
      </SectionDescription>
    </Section>
  </DefaultLayout>
);
