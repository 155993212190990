export const PUBLICATIONS = [
  {
    link: 'https://www.sciencedirect.com/science/article/pii/S2666501820300040',
    label: 'Atrial fibrillation detection from raw photoplethysmography waveforms: A deep learning application',
    notes: '[1] [2]',
    source: 'Heart Rhythm O2, 1(1)',
  },
  {
    link: 'https://arxiv.org/abs/2003.07896',
    label: 'Teacher-Student Domain Adaptation for Biosensor Models',
    notes: '[1] [2]',
    source: 'arXiv.org, Cornell University',
  },
  {
    link: 'https://www.ahajournals.org/doi/abs/10.1161/circ.138.suppl_1.16132',
    label: 'Atrial Fibrillation Detection Utilizing Raw Photoplethysmography Waveform Data From a Fitness Tracker',
    notes: '[1] [2]',
    source: 'Circulation (Vol. 138)',
  },
  {
    link: 'https://arxiv.org/abs/1807.10707',
    label: 'End-to-end Deep Learning from Raw Sensor Data: Atrial Fibrillation Detection using Wearables',
    notes: '[1] [2]',
    source: 'arXiv.org, Cornell University',
  },
  {
    link: 'https://pubmed.ncbi.nlm.nih.gov/27257217/',
    label: 'Validation of Consumer-Based Hip and Wrist Activity Monitors in Older Adults With Varied Ambulatory Abilities',
    notes: '[1] [2]',
    source: 'J Gerontol A Biol Sci Med Sci',
  },
  {
    link: 'https://pubmed.ncbi.nlm.nih.gov/26484953/',
    label: 'Twenty-four Hours of Sleep, Sedentary Behavior, and Physical Activity with Nine Wearable Devices',
    notes: '[1] [2]',
    source: 'Med Sci Sports Exerc',
  },
  {
    link: 'https://pubmed.ncbi.nlm.nih.gov/24876538/',
    label: 'Accuracy of a Novel Noninvasive Multisensor Technology to Estimate Glucose in Diabetic Subjects During Dynamic Conditions',
    notes: '[1] [2]',
    source: 'J Diabetes Sci Technol',
  },
  {
    link: 'https://www.researchgate.net/publication/257753256_Accuracy_of_the_SenseWear_Armband_Mini_and_the_BodyMedia_FIT_in_resistance_training',
    label: 'Accuracy of the SenseWear Armband Mini and the BodyMedia FIT in resistance training',
    notes: '[1] [2]',
    source: 'J Science and Medicine in Sport',
  },
  {
    link: 'https://openaccesspub.org/jbd/article/75',
    label: 'Development of a Model-Based Noninvasive Glucose Monitoring Device for Non-Insulin Dependent People',
    notes: '[1] [2]',
    source: 'J Bioinformatics and Diabetes, 1(1)',
  },
  {
    link: 'https://pubmed.ncbi.nlm.nih.gov/17426327/',
    label: 'Field validation of the MTI Actigraph and BodyMedia armband monitor using the IDEEA monitor',
    notes: '[1] [2]',
    source: 'Obesity (Silver Spring)',
  },
  {
    link: 'https://pubmed.ncbi.nlm.nih.gov/15756165/',
    label: 'Measuring energy expenditure in cardiac patients using the Body Media Armband versus indirect calorimetry. A validation study',
    notes: '[1] [2]',
    source: 'J Sports Med Phys Fitness',
  },
  {
    link: 'https://pubs.asahq.org/anesthesiology/article/100/6/1469/6613/Continuous-Noninvasive-and-Localized-Microvascular',
    label: 'Continuous, Noninvasive, and Localized Microvascular Tissue Oximetry Using Visible Light Spectroscopy',
    notes: '[3]',
    source: 'Anesthesiology',
  }
];
